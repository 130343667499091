<template>
  <div class="Disclaimers">
    <div class="name">
        免责声明
    </div>
    <div class="collapse">
      <div class="p-box">
        <p>
            1.文章内容不得违反宪法和相关法律、法规。
        </p>
        <p>
            2.文章必须是署名作者原创的研究成果，无剽窃内容，不涉及泄密问题，无作者署名纷争，不存在一稿多投。
        </p>
        <p>
            3.文章在理论、方法、结果上有创新内容，具有一定的理论意义或实用价值。
        </p>
        <p>
            4.文章要求论点明确、论述严谨、数据充分可靠、图表设计合理、文字简明通顺，具有科学性和可读性。
        </p>
        <p>
            5.文章使用语言文字必须符合国家法律规定和有关标准、规范。
        </p>
        <p>
            6.文章必须要素齐全，按顺序包括题目、作者姓名、作者单位(地址及邮编)、中英文摘要、中英文关键词、中图分类号、正文、参考文献，并在首页的地脚处注明投稿日期、基金项目和项目编号、第一作者简介（姓名、出生年、性别、职称与学位、主要研究方向、E-mail 地址和联系电话）。
        </p>
        <p>
            英文摘要按顺序包括：英文题目、作者姓名（用汉语拼音）、英文作者单位（地址、邮编、国名）、英文摘要、英文关键词。
        </p>
        <p>
            7.文章应采用word文档电子版，字数以5000字以内为宜。
        </p>
        <p>
            8.文章发表需经过初审、复审和终审三个环节，投稿3天后便可在本刊网站稿件追踪栏查询录用状态。
        </p>
        <p>
            9.本站联系方式：
        </p>
        <p>
            联系电话：027-87638980
        </p>
        <p>
            联系人：陶老师
        </p>
        <p>
            编辑部地址：武汉市洪山区珞珈山路19号中科开物大厦12楼
        </p>
        <br>
        <p class="text-r">
            可信大数据武汉有限公司
        </p>
        <p>
        </p>
        <p>
        </p>
    </div>
    </div>
      
  </div>
</template>

<script>
export default {
  name: 'Disclaimers',
 components:{},
  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.collapse{
  padding: 20px 0;
  p{
  color: rgba(0, 18, 22, 0.65);
  text-indent: 2em;
  padding: 5px 0;
  line-height:1.769230769230769;
  }
  .text-r{
    text-align: right;
  }
}
.Disclaimers {
    padding: 0 32px 0;
}
.name {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0,18,22,.85);
    line-height: 36px;
    padding: 32px 0 32px 0;
    border-bottom: 1px solid #ebeef5;
}

</style>
